import React from 'react'
import SideBar from './SideBar'
import { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import PlaceIcon from '@mui/icons-material/Place';
import { useParams } from 'react-router-dom'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { setAuthToken } from './setAuthToken';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import Success from './success.png'
import S3FileUpload from 'react-s3';
import cancel from './cancelled (1).png'
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Buffer } from "buffer";
Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;
function Cartitems() {
    let { id } = useParams()
    const [Order, SetOrder] = useState([])
    const [cart, setcart] = useState([])
    const [trackingcontent,setTracking]=useState('')
    const [customer, setcustomer] = useState('')
    const [all,setall]=useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const fileInput = useRef();
    const [report, setReport] = useState()
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open5,setOpen5]=useState(false)
    const [open4,setOpen4]=useState(false)
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    let Allreports = []

    let navigate = useNavigate()

    const handleClickOpen = (e) => {
        setOpen(true);
    };
    const handleClickOpen1 = (e) => {
        setOpen1(true);
    };
    const handleClickOpen2 = (e) => {
        setOpen2(true);
    };
    const handleClickOpen3 = (e) => {
        setOpen3(true);
    };
    const handleClickOpen4=(e)=>{
         setOpen4(true)
    }
    const handleClickOpen5=(e)=>{
        setOpen5(true)
    }
    const handleClose = () => {
        setOpen(false);
        setOpen1(false);
        setOpen2(false);
        setOpen3(false);
        setOpen4(false)
        setOpen5(false)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const config = {
        bucketName:'aiwellbucket',
        dirName:'reports',
        region:'ap-south-1',
        accessKeyId:'AKIA4I3PXMDL2YGS22VL',
        secretAccessKey:'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',
        cors: [
            {
                maxAge: "1 day",
                allowedOrigins: ["*"],
                allowedHeaders: ["*"],
                allowedMethods: ["GET", "PUT", "POST", "DELETE", "HEAD"],
            },
        ],
    }

    const handleUpload = async (file) => {
        await S3FileUpload.uploadFile(file, config)
            .then(data => {
                console.log(data)
                Allreports.push(data)
            }
            )
            .catch(err => console.error(err))
    }

    const uploadtos3 = async () => {
        try {
            let token=sessionStorage.getItem('AccessToken')
            setAuthToken(token)
            let res = await axios.post('https://diabapi.ayurai.in/api/data/uploadreports', {
                UID: Order.userid,
                DATA: {
                    Reports: Allreports,
                    CreatedAt: new Date()
                }

            })
            if (res.status === 200) {
                handleClose()
                updateOrder('delivered')
            }
        }
        catch (error) {
            if (error.response.data.error.message === 'jwt expired') {
                renew()
           }
        }
    }
   
    let handleFileUpload = async (e) => {
        e.preventDefault();
        let reportsArray = fileInput.current.files;
        for (let i = 0; i < reportsArray.length; i++) {
            handleUpload(reportsArray[i]);
        }


    }
    let renew = async () => {
        try {
            let data = sessionStorage.getItem("User_details")
            let User_details = JSON.parse(data)
            let token=sessionStorage.setItem("refreshToken")
            let res = await axios.post(`https://diabapi.ayurai.in/auth/renew`, {
            email:User_details.Email,
            token
            })
            if (res.status === 200) {
                sessionStorage.setItem('AccessToken',res.data.access_token)
                getOneOrder()
            }
        }
        catch (error) {
            console.log(error.response.data)
        }
    }

    let getOneOrder = async () => {
        try {
            let token=sessionStorage.getItem('AccessToken')
            setAuthToken(token)
            let res = await axios.post('https://diabapi.ayurai.in/api/data/getOneOrders', {
                orderid: id
            })
            if (res.status === 200) {
                SetOrder(res.data.Data)
                setall(res.data)
                setcart(res.data.Data.cartitems)
                setcustomer(res.data.Data.partners.customer[0].billing.name)

            }
        }
        catch (error) {
            if (error.response.data.error.message === 'jwt expired') {
                renew()
           }
           else{
            navigate('/Login')
           }
        }
    }

    let updateOrder = async (OrderStatus) => {
        try {
            let res = await axios.post('https://diabapi.ayurai.in/api/data/updateOneOrder', {
                key: "orderstatus",
                value: OrderStatus,
                orderid: id,
                companyid: Order.companyid,
                trackingcontent:trackingcontent
            })
            if (res.status === 200) {
                handleClose()
                navigate(-1)
            }
        }
        catch (error) {
            renew()
        }
    }

    useEffect(() => {
        let Token = sessionStorage.getItem('AccessToken')
        setAuthToken(Token)
        getOneOrder();
    }, [])

    useEffect(() => {
        if (!sessionStorage.getItem('AccessToken')) {
            navigate('/Login')
        }
    }, [])
   

    return <>

        <SideBar />
        <div className="p-4 sm:ml-64">
            <div className="p-4 rounded-lg dark:border-gray-700 mt-14">
                <div className='Cart-page'>

                    <div className='Customer-details'>
                        <p>Name : <span>{customer}</span></p>
                        <p>Email : <span>{Order?.email}</span></p>
                        <p>Mobile Number :<span>{Order?.phone}</span></p>
                        <p>User id : <span>{Order?.userid}</span></p>
                        <p>Order id : <span>{Order?.orderid}</span></p>
                        <p>Placed on : <span>{Order?.dateandtime}</span></p>
                        <p>Address <PlaceIcon style={{ fontSize: 'medium', color: "red" }} /> : <span>{Order?.address}</span></p>
                        <p>Total : <span>₹ {Order.total}</span></p>
                        {all?.Invoice?.Invoice && <a href={all?.Invoice?.Invoice} download target='_blank'><p className='download-invoice'>Download invoice <SaveAltIcon/></p></a>}
                    </div>
                    <div className='Order_Status' style={Order.orderstatus === 'cancelled' ? { backgroundColor: "#FFCCCB" } : Order?.orderstatus === 'received' ? { backgroundColor: '#FFFFE0' } : Order.orderstatus === 'delivered' ? { backgroundColor: 'lightcyan' } : { backgroundColor: "#89CFF0" }}>
                        <div className='Order-status-element' >
                            <p>Order Status : <span>{Order.orderstatus}</span></p>
                            {Order.orderstatus === "delivered" ? <img src={Success}></img> : Order.orderstatus === 'cancelled' ? <img src={cancel}></img> : <></>}
                        </div>
                    </div>
                    <div className='Cart-items'>
                        <p>Cart items</p>
                        <TableContainer sx={{ maxHeight: 440 }} className='Table mt-5' >
                            <Table stickyHeader aria-label="sticky table" >
                                <TableHead>
                                    <TableRow>

                                        <TableCell className='Table-header'
                                            style={{ minWidth: "100px" }}

                                        >
                                            Product
                                        </TableCell>
                                        <TableCell className='Table-header'
                                            align={"center"}
                                            style={{ minWidth: "100px" }}

                                        >
                                            Quantity
                                        </TableCell>
                                        <TableCell className='Table-header'
                                            align={"center"}
                                            style={{ minWidth: "100px" }}

                                        >
                                            Price
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(cart).map(([type, make]) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={type}>


                                                <TableCell >
                                                    <div className='Product-details'>
                                                        <img src={make.image} ></img>
                                                        <div>
                                                            <p>{make.name}</p>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    {make.qty}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                ₹ {make.price}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                            {/* <TableFooter align="right">
                            <TablePagination

                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={Orders.length}

                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableFooter> */} 
                        </TableContainer>
                    </div>
                    <div className='Action_buttons'>
                    {Order.orderstatus === "placed"
                            ?<Button style={{ backgroundColor: "red", color: "white", borderRadius: "8px" }} onClick={() => handleClickOpen1()}> Cancel </Button>:<Button style={{ borderRadius: "8px" }} disabled>Cancel</Button>}
                        {Order.orderstatus === "placed" ?
 <Button style={{ backgroundColor: "green", color: "white", borderRadius: "8px" }} onClick={() => handleClickOpen2()} >Confirm</Button>:                            <Button style={{ borderRadius: "8px" }} disabled>Confirm</Button> }
                            {/* <Button style={Order.orderStatus==='placed'?{ backgroundColor: "red", color: "white", borderRadius: "8px" }:{ borderRadius: "8px" }} onClick={() => handleClickOpen1()} disabled={Order.OrderStatus!='placed'}>Cancel</Button>
                            <Button style={Order.orderStatu==='placed'?{ backgroundColor: "green", color: "white", borderRadius: "8px" }:{ borderRadius: "8px" }} onClick={() => handleClickOpen2()} disabled={Order.OrderStatus='confirmed'} >Confirm</Button> */}
                            
                        {Order.orderstatus==='confirmed'?<Button style={{  backgroundColor: "initial",
backgroundImage: 'linear-gradient(#8614f8 0, #760be0 100%)', color: "white", borderRadius: "8px" }} onClick={() => handleClickOpen3()} >Dispatched </Button>
                        :<Button style={{ borderRadius: "8px" }} disabled>Dispatched</Button>}

                        {Order.campanyid!='1003'?Order.orderstatus==='dispatched'?<Button style={{ backgroundColor: "green", color: "white", borderRadius: "8px" }} onClick={() => handleClickOpen4()} >Delivered</Button>
                        :<Button style={{ borderRadius: "8px" }} disabled>Delivered</Button>:<></>}

                           
                        {Order.companyid === '1003' && Order.orderstatus === 'dispatched' ? <Button style={{ backgroundColor: "blue", color: "white", borderRadius: "8px" }} onClick={() => handleClickOpen5()}>Received Sample</Button> : <></>}
                        {Order.orderstatus === 'received'&& Order.companyid === '1003'?
                            <><label>
                                <Button style={{ backgroundColor: "blue", color: "white", borderRadius: "8px" }}
                                    htmlFor="file-upload" onClick={(e) => handleClickOpen(e)}>Upload Report</Button></label>
                            </> : <></>}
                        {Order.Orderstatus != 'delivered' && Order.OrderStatus=== 'received' && Order.companyid==='1003'?    
                        <Button style={{ backgroundColor: "blue", color: "white", borderRadius: "8px" }} onClick={(e) => handleClickOpen(e)}>Upload Raw Data</Button>:<></>
                        }
                    </div>
                              
                </div>
            </div>
            <Dialog 
                className='dialog' 
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id='DialogTitle'>{"Upload Report"}</DialogTitle>
                <DialogContent>
                    <div className='upload_report'>
                        <Button variant="contained" component="label">
                            select &nbsp;<CloudUploadIcon />
                            <input hidden type='file' ref={fileInput} multiple onChange={(e) => handleFileUpload(e)} />
                        </Button>
                    </div>
                    <DialogContentText id="alert-dialog-slide-description" >
                      Select files from your Computer And Click 'submit' after selecting the files
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={uploadtos3}
                        variant="outlined">Submit</Button>
                    <Button onClick={handleClose} variant="outlined" color="error">Cancel</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                className='dialog'
                open={open3}
                // TransitionComponent={Transition}
                // keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id='DialogTitle'>{"Tracking ID"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Enter Tracking id here
                    </DialogContentText>
                    <input
                        id="filled-search"
                        type="search"
                        variant="filled"
                       onChange={(e)=>{setTracking(e.target.value)}}
                        
                    />
                    {/* <input type='text' ref={trackingidinput}></input> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => updateOrder('dispatched')}
                        variant="outlined">Submit</Button>
                    <Button onClick={handleClose} variant="outlined" color="error">Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                className='dialog'
                open={open5}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id='DialogTitle'>{"Are you Sure ?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Do you want to Change the status to Received
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => updateOrder('received')}
                        variant="outlined">Yes</Button>
                    <Button onClick={handleClose} variant="outlined" color="error">No</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                className='dialog'
                open={open4}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id='DialogTitle'>{"Are you Sure ?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Do you want to Change the status to Delivered
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => updateOrder('delivered')}
                        variant="outlined">Yes</Button>
                    <Button onClick={handleClose} variant="outlined" color="error">No</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                className='dialog'
                open={open1}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id='DialogTitle'>{"Are you Sure ?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Do you want to Cancel this Order
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => updateOrder('cancelled')}
                        variant="outlined">Yes</Button>
                    <Button onClick={handleClose} variant="outlined" color="error">No</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                className='dialog'
                open={open2}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id='DialogTitle'>{"Are you Sure ?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Do you want to Confirm this Order
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => updateOrder('confirmed')}
                        variant="outlined">Yes</Button>
                    <Button onClick={handleClose} variant="outlined" color="error">No</Button>
                </DialogActions>
            </Dialog>
        </div>
    </>
}

export default Cartitems